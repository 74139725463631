var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_box"},[_c('header',{staticClass:"header container"},[_c('h1',{staticClass:"logo"},[_c('router-link',{staticClass:"link",attrs:{"to":"/"}})],1),_c('div',{staticClass:"h_con"},[_c('ul',{staticClass:"clearfloat"},[_c('li',{staticClass:"phone"},[_vm._v("4000-585-116")]),_vm._m(0),_vm._m(1),_vm._m(2),(!_vm.uid)?_c('li',[_c('router-link',{staticClass:"h_login",attrs:{"to":"/login"}},[_vm._v("登录")])],1):_vm._e(),(!_vm.uid)?_c('li',[_c('em',[_vm._v("|")])]):_vm._e(),(!_vm.uid)?_c('li',[_c('router-link',{staticClass:"h_register",attrs:{"to":"/register"}},[_vm._v("注册")])],1):_vm._e(),(_vm.uid)?_c('li',{staticClass:"top_user"},[_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(_vm.uname))])]):_vm._e(),(_vm.uid)?_c('li',{staticClass:"top_quit"},[_c('a',{on:{"click":_vm.logout}},[_vm._v("退出")])]):_vm._e()]),_vm._m(3)])]),_c('nav',{staticClass:"navbar"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"nav"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/"}},[_c('span',{staticClass:"icon_home"}),_vm._v("首页 ")])],1),_c('li',[_c('router-link',{attrs:{"to":"/about"}},[_vm._v("关于净美仕")])],1),_c('li',[_c('router-link',{class:{
                'router-link-exact-active': this.$route.name == 'NewsDetails',
              },attrs:{"to":"/news"}},[_vm._v("公司动态")])],1),_c('li',{staticClass:"nav_pd"},[_c('router-link',{class:{
                'router-link-exact-active': this.$route.name == 'Product',
              },attrs:{"to":"/product/1"}},[_vm._v(" 产品中心 "),_c('span',{staticClass:"icon_pd"})]),_c('div',{staticClass:"pd_dropdown"},[_c('router-link',{attrs:{"to":"/product/1"}},[_vm._v("净美仕净化器")]),_c('router-link',{attrs:{"to":"/product/2"}},[_vm._v("净美仕滤网")])],1)],1),_c('li',[_c('router-link',{attrs:{"to":"/contact"}},[_vm._v("联系我们")])],1)])]),(_vm.uid)?_c('div',{staticClass:"s_cart"},[_c('router-link',{attrs:{"to":"/cart"}},[_c('span',{staticClass:"icon_cart"}),_vm._v("购物车(0) "),_c('span',{staticClass:"icon_sj"})])],1):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('em',[_vm._v("|")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"weixin",attrs:{"href":"#"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"weibo",attrs:{"href":"#"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search clearfloat"},[_c('button',{attrs:{"type":"button"}}),_c('input',{attrs:{"type":"text"}})])
}]

export { render, staticRenderFns }