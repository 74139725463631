<template>
  <div class="footer">
    <!-- 复制 footer.html 代码到此处 -->

    <div class="container">
      <div class="f_con clearfloat">
        <ul>
          <li>
            <h3>走进净美仕</h3>
            <a href>公司简介</a>
            <a href>净美仕精神</a>
            <a href>品牌历程</a>
          </li>
          <li>
            <h3>成为合作伙伴</h3>
            <a href>成为核心供应商</a>
            <a href>电商经销入口</a>
            <a href>成为线下代理商</a>
          </li>
          <li>
            <h3>售后服务</h3>
            <a href>在线报修</a>
            <a href>售后政策</a>
            <a href>下载中心</a>
          </li>
          <li>
            <h3>加入净美仕</h3>
            <a href>招聘贤才</a>
            <a href>联系我们</a>
            <a href>网站地图</a>
          </li>
        </ul>
        <span class="qrcode"></span>
        <div class="kefu">
          <h4>4000-585-116</h4>
          <span>周一至周日9:00-18:00</span>
          <a href="#">金 牌 客 服</a>
        </div>
      </div>
      <div class="copyright">
        Copyright © 2014 Apple Inc 保留所有权利。使用条款|客户隐私政策 ©
        北京净美仕环境科技有限公司 <a href="https://beian.miit.gov.cn/">鲁ICP备2021006813号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
