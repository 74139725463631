import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/about",
    name: "About",
    // 懒加载写法: 用的时候才会引入
    component: () => import("../views/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue"),
  },
  {
    // : 代表参数
    path: "/news_details/:nid",
    name: "NewsDetails",
    component: () => import("../views/NewsDetails.vue"),
  },
  {
    path: "/product/:type",
    name: "Product",
    component: () => import("../views/Product.vue"),
  },
  {
    path: "/product_details/:pid",
    name: "ProductDetails",
    component: () => import("../views/ProductDetails.vue"),
    // https://router.vuejs.org/zh/guide/essentials/passing-props.html
    // 此属性代表可以通过属性来接收路由参数
    props: true,
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import("../views/Cart.vue"),
  },
  {
    path: "*",
    name: "notFound",
    component: Index,
  },
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
