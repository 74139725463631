<template>
  <div>
    <!-- 根组件: 单页应用实际呈现出来的 页面 -->
    <!-- 具体加载流程: index.html -> main.js -> App.vue -->
    <Header />
    <!-- router-view: 会根据 url地址中的路径变化, 显示为不同的组件 -->
    <!-- 路径和组件对应关系的配置: router/index.js 中 -->
    <router-view />
    <Footer />
  </div>
</template>

<script>
// 组件的使用分3步: 引入 -> 注册 -> 使用
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  components: {
    Header: Header, //语法糖:  Header
    Footer,
  },
};
</script>

<style>
/* 引入css文件 */
@import "./assets/css/common.css";
@import "./assets/css/mycss.css";
</style>