export function date(timestamp) {
  // 转化时间戳为: 年-月-日
  let date = new Date(parseInt(timestamp));

  let year = date.getFullYear();
  let month = date.getMonth() + 1; //0-11 转 1-12
  let day = date.getDate();

  return `${year}-${month}-${day}`;
}
